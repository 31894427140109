
import {
  defineComponent,
  reactive,
  toRefs,
  SetupContext,
  onMounted,
} from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import userSession from '@/modules/auth/sessionModule';
import { updateProfile } from '@/modules/requests';
import { useStore } from 'vuex';

export default defineComponent({
  inheritAttrs: false,
  props: {
    personalInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  emit: ['emitSuccess', 'back'],
  setup: (props, { emit }: SetupContext) => {
    const { basicForm, getFormData, mapFormErrors } = resource();
    const { validateForm } = validation();

    const { mapProfileData, genderOptions, relationshipOptions } =
      userSession();

    const { personalInfo } = toRefs(props);

    const { dispatch } = useStore();

    const data: any = reactive({
      form: basicForm([
        {
          name: 'next_of_kin_name',
          rules: 'required',
        },
        {
          name: 'next_of_kin_email',
          rules: 'required|email',
        },
        {
          name: 'next_of_kin_mobile',
          rules: 'required|phone',
        },

        {
          name: 'next_of_kin_relation',
          rules: 'required',
        },
        {
          name: 'next_of_kin_gender',
          rules: 'required',
        },
      ]),
      errorModal: null,
    });

    onMounted(() => setTimeout(() => mapProfileData(data.form), 500));

    const submitForm = async (): Promise<any> => {
      if (!validateForm(data.form)) return false;
      data.form.loading = true;

      await updateProfile({ ...personalInfo.value, ...getFormData(data.form) })
        .then((response) => {
          dispatch('updateUser', response?.data).then(() =>
            emit('emitSuccess'),
          );
        })
        .catch((error) => {
          data.form.error = error?.response?.data?.message;
          mapFormErrors(data.form, error?.response?.data?.errors);
          data.errorModal.open();
        })
        .finally(() => (data.form.loading = false));
    };

    return { ...toRefs(data), genderOptions, relationshipOptions, submitForm };
  },
});
