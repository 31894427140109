<template>
  <div
    class="inline-flex items-center mb-3 cursor-pointer"
    @click="$emit('back')"
  >
    <icon-back />
    <span class="ml-2 text-xs text-invest-purple">Back</span>
  </div>
  <div class="text-2xl font-bold text-invest-primary">
    Next of kin information
  </div>
  <p class="mt-3 mb-8 text-xs text-invest-dark">
    Provide basic information of your next of kin.
  </p>

  <form @submit.prevent="submitForm">
    <form-input
      name="next_of_kin_name"
      :form="form"
      v-model="form.data.next_of_kin_name.value"
    >
      Full name
    </form-input>

    <form-input
      name="next_of_kin_email"
      :form="form"
      v-model="form.data.next_of_kin_email.value"
    >
      Email address
    </form-input>
    <form-input
      name="next_of_kin_mobile"
      :form="form"
      v-model="form.data.next_of_kin_mobile.value"
    >
      Phone number
    </form-input>
    <form-input
      type="select"
      :form="form"
      name="next_of_kin_relation"
      :options="relationshipOptions"
      :defaultValue="`${form.data.next_of_kin_relation.value || ''}`"
      v-model="form.data.next_of_kin_relation.value"
    >
      Relationship
    </form-input>
    <form-input
      type="select"
      :form="form"
      name="next_of_kin_relation"
      :options="genderOptions"
      :defaultValue="`${form.data.next_of_kin_gender.value || ''}`"
      v-model="form.data.next_of_kin_gender.value"
    >
      Gender
    </form-input>
    <div class="mt-8">
      <button type="submit" class="btn btn-primary" :disabled="form.loading">
        <loader v-if="form.loading" />
        <span v-else>Submit</span>
      </button>
    </div>
  </form>
  <alert-modal ref="errorModal" alert-type="error">
    <template #title> {{ form.error }} </template>
  </alert-modal>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  SetupContext,
  onMounted,
} from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import userSession from '@/modules/auth/sessionModule';
import { updateProfile } from '@/modules/requests';
import { useStore } from 'vuex';

export default defineComponent({
  inheritAttrs: false,
  props: {
    personalInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  emit: ['emitSuccess', 'back'],
  setup: (props, { emit }: SetupContext) => {
    const { basicForm, getFormData, mapFormErrors } = resource();
    const { validateForm } = validation();

    const { mapProfileData, genderOptions, relationshipOptions } =
      userSession();

    const { personalInfo } = toRefs(props);

    const { dispatch } = useStore();

    const data: any = reactive({
      form: basicForm([
        {
          name: 'next_of_kin_name',
          rules: 'required',
        },
        {
          name: 'next_of_kin_email',
          rules: 'required|email',
        },
        {
          name: 'next_of_kin_mobile',
          rules: 'required|phone',
        },

        {
          name: 'next_of_kin_relation',
          rules: 'required',
        },
        {
          name: 'next_of_kin_gender',
          rules: 'required',
        },
      ]),
      errorModal: null,
    });

    onMounted(() => setTimeout(() => mapProfileData(data.form), 500));

    const submitForm = async (): Promise<any> => {
      if (!validateForm(data.form)) return false;
      data.form.loading = true;

      await updateProfile({ ...personalInfo.value, ...getFormData(data.form) })
        .then((response) => {
          dispatch('updateUser', response?.data).then(() =>
            emit('emitSuccess'),
          );
        })
        .catch((error) => {
          data.form.error = error?.response?.data?.message;
          mapFormErrors(data.form, error?.response?.data?.errors);
          data.errorModal.open();
        })
        .finally(() => (data.form.loading = false));
    };

    return { ...toRefs(data), genderOptions, relationshipOptions, submitForm };
  },
});
</script>
